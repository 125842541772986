import { UserOrganisationWithName } from "context/user-context/user.state";
import { AnalyticsData, CorporateData, User } from "database/collections/users";
import dayjs from "dayjs";
import { MIN_SESSIONS_HOSTED_TO_GET_FLOWN_FULL } from "features/sessions/shared";
import { getConfig } from "services/config";
import { SUBSCRIPTION_TYPES } from "shared/subscription.types";
import { FLOWN_FACILITATOR_TAG } from "utils/constants";

export type UserRole = typeof USER_ROLES[keyof typeof USER_ROLES];

export const USER_ROLES = {
  ANONYMOUS: "anonymous",
  EXPIRED: "expired",
  FREE_TRIAL: "free trial",
  MONTHLY_FREE_TRIAL: "monthly free trial",
  YEARLY_FREE_TRIAL: "yearly free trial",
  // Leaving quarterly still here in case it comes back
  QUARTERLY_FREE_TRIAL: "quarterly free trial",
  FREE_TRIAL_EXPIRED: "free trial expired",
  FREE: "free",
  FREE_HOST: "free-host",
  FREE_CREDITS: "free-credits",
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  YEARLY: "yearly",
  LIFETIME: "lifetime",
  COMPLIMENTARY: "complimentary",
  FLOWN: "flown",
  CORPORATE: "corporate",
  CORPORATE_ADMIN: "corporate-admin",
  CORPORATE_USER: "corporate-user",
  CORPORATE_EXPIRED: "corporate-expired", // Deprecated, kept for historical reasons / in case we need again
  CORPORATE_INACTIVE: "corporate-inactive",
  CORPORATE_SUSPENDED: "corporate-suspended",
  FACILITATOR: "facilitator",
  ACCESS_TO_WORK: "access-to-work",
  DELETED: "deleted",
  BLOCKED: "blocked",
} as const;

const PAID_USER_ROLES: UserRole[] = [
  USER_ROLES.YEARLY,
  USER_ROLES.QUARTERLY,
  USER_ROLES.MONTHLY,
  USER_ROLES.LIFETIME,
];

/**
 * Paying members or members with Full access that are not
 * in their trial period
 */
export const FLOWN_FULL_MEMBERS: UserRole[] = [
  ...PAID_USER_ROLES,
  USER_ROLES.COMPLIMENTARY,
  USER_ROLES.FLOWN,
  USER_ROLES.CORPORATE,
  USER_ROLES.CORPORATE_ADMIN,
  USER_ROLES.CORPORATE_USER,
  USER_ROLES.FACILITATOR,
  USER_ROLES.ACCESS_TO_WORK,
  USER_ROLES.FREE_HOST,
];

export const AUTHORIZED_USER_ROLES: UserRole[] = [
  USER_ROLES.FREE_TRIAL,
  USER_ROLES.MONTHLY_FREE_TRIAL,
  USER_ROLES.YEARLY_FREE_TRIAL,
  USER_ROLES.QUARTERLY_FREE_TRIAL,
  USER_ROLES.FREE,
  USER_ROLES.FREE_CREDITS,
  ...FLOWN_FULL_MEMBERS,
];

export const CORPORATE_USER_ROLES = {
  ADMIN: "admin",
  USER: "user",
};

export type CorporateUserRole =
  typeof CORPORATE_USER_ROLES[keyof typeof CORPORATE_USER_ROLES];

export const CORPORATE_USER_STATES = {
  ACTIVE: "active",
  PENDING: "pending",
  DEACTIVATED: "deactivated",
  SUSPENDED: "suspended",
};

export type CorporateUserStates =
  typeof CORPORATE_USER_STATES[keyof typeof CORPORATE_USER_STATES];

const CORPORATE_ROLES: UserRole[] = [
  USER_ROLES.CORPORATE_ADMIN,
  USER_ROLES.CORPORATE_USER,
];

export const UNSUBSCRIBED_USER_ROLES: UserRole[] = [
  USER_ROLES.FREE_TRIAL,
  USER_ROLES.EXPIRED,
  USER_ROLES.FREE_TRIAL_EXPIRED,
  USER_ROLES.FREE,
  USER_ROLES.FREE_CREDITS,
  USER_ROLES.DELETED,
];

export const CAN_ADD_FREE_TRIAL: UserRole[] = [
  USER_ROLES.FREE_TRIAL,
  USER_ROLES.EXPIRED,
  USER_ROLES.FREE_TRIAL_EXPIRED,
  USER_ROLES.FREE,
];

type GetUserRoleProp = Pick<
  User,
  | "createTime"
  | "freeTrialExpiry"
  | "email"
  | "subscription"
  | "stripeCustomerId"
  | "organisation"
  | "deleted"
  | "canExtendFreeTrial"
> & { organisationRenewalDate: string } & {
  analyticsData?: Pick<
    AnalyticsData,
    "sessionsAttended" | "fixedFormatSessionsHostedLast30Days"
  >;
};

// Methods that are used to create the user role
export const isValidSubscription = (
  subscriptionRenewalDate?: string,
  today = dayjs()
) =>
  subscriptionRenewalDate
    ? dayjs(subscriptionRenewalDate).add(
        getConfig("SUBSCRIPTION_GRACE_PERIOD"),
        "day"
      ) >= today
    : false;

const hasFreeTrial = (freeTrialExpiry: string | undefined, today = dayjs()) => {
  if (freeTrialExpiry) {
    return dayjs(today).diff(freeTrialExpiry) < 0;
  }
  return false;
};

export const getFreeTrialRemainingDays = (
  freeTrialExpiry: string | undefined,
  today = dayjs()
) => {
  if (freeTrialExpiry) {
    return Math.max(dayjs(freeTrialExpiry).diff(today, "days"), 0);
  } else {
    return 0;
  }
};

const hasMinSessionsToBecomeFreeHost = (fixedFormatSessionsHosted: number) => {
  return fixedFormatSessionsHosted >= MIN_SESSIONS_HOSTED_TO_GET_FLOWN_FULL;
};

const isFreeOrFreeHost = (user: GetUserRoleProp) => {
  if (
    hasMinSessionsToBecomeFreeHost(
      user.analyticsData?.fixedFormatSessionsHostedLast30Days || 0
    )
  ) {
    return USER_ROLES.FREE_HOST;
  }

  return USER_ROLES.FREE;
};

export const computeUserRole = (
  user: GetUserRoleProp,
  today = dayjs()
): UserRole => {
  if (user.deleted) return USER_ROLES.DELETED;
  if (user.subscription?.type === SUBSCRIPTION_TYPES.BLOCKED) {
    return USER_ROLES.BLOCKED;
  }
  if (
    user.subscription?.type === SUBSCRIPTION_TYPES.FLOWN &&
    isValidSubscription(user.subscription.renewalDate, today)
  ) {
    return USER_ROLES.FLOWN;
  }

  // Capture all corporate users right away
  // TODO: we never assign users to a corporate expired role - Segment below has 0 users
  // https://fly.customer.io/workspaces/110792/journeys/segments/1165/people
  if (
    user.organisation &&
    user.organisationRenewalDate &&
    isValidSubscription(user.organisationRenewalDate, today)
  ) {
    if (user.organisation.status === CORPORATE_USER_STATES.DEACTIVATED) {
      return USER_ROLES.CORPORATE_INACTIVE;
    }
    if (user.organisation.status === CORPORATE_USER_STATES.SUSPENDED) {
      return USER_ROLES.CORPORATE_SUSPENDED;
    }
    if (user.organisation.userRole === CORPORATE_USER_ROLES.ADMIN) {
      return USER_ROLES.CORPORATE_ADMIN;
    }
    if (user.organisation.userRole === CORPORATE_USER_ROLES.USER) {
      return USER_ROLES.CORPORATE_USER;
    }
  }

  if (
    user.subscription?.type === SUBSCRIPTION_TYPES.FREE_CREDITS ||
    user.subscription?.type === SUBSCRIPTION_TYPES.FACILITATOR ||
    user.subscription?.type === SUBSCRIPTION_TYPES.ACCESS_TO_WORK ||
    user.subscription?.type === SUBSCRIPTION_TYPES.LIFETIME
  ) {
    return user.subscription.type;
  }

  // A Free user needs to consciously have chosen Free
  // We still check whether they are a free host
  if (user.subscription?.type === SUBSCRIPTION_TYPES.FREE) {
    return isFreeOrFreeHost(user);
  }

  // Check the data to establish if a user should be a free host
  const fixedFormatSessionsHostedLast30Days =
    user.analyticsData?.fixedFormatSessionsHostedLast30Days || 0;

  if (user?.subscription?.renewalDate) {
    if (
      !isValidSubscription(user.subscription.renewalDate, today) &&
      !hasFreeTrial(user.freeTrialExpiry, today)
    ) {
      // Check if the user is a free host
      if (hasMinSessionsToBecomeFreeHost(fixedFormatSessionsHostedLast30Days)) {
        return USER_ROLES.FREE_HOST;
      }

      return USER_ROLES.EXPIRED;
    } else {
      if (
        user.subscription.type === SUBSCRIPTION_TYPES.COMPLIMENTARY ||
        user.subscription.type === SUBSCRIPTION_TYPES.CORPORATE
      ) {
        return user.subscription.type;
      }
      if (
        user.stripeCustomerId &&
        user.subscription.stripeSubscriptionId &&
        !hasFreeTrial(user.freeTrialExpiry, today)
      ) {
        if (
          user.subscription.type === SUBSCRIPTION_TYPES.MONTHLY ||
          user.subscription.type === SUBSCRIPTION_TYPES.QUARTERLY ||
          user.subscription.type === SUBSCRIPTION_TYPES.YEARLY
        ) {
          return user.subscription.type;
        }
      } else if (
        user.stripeCustomerId &&
        user.subscription.stripeSubscriptionId &&
        hasFreeTrial(user.freeTrialExpiry, today) &&
        !user.subscription.isCancelled &&
        isValidSubscription(user.subscription.renewalDate, today)
      ) {
        if (user.subscription.type === SUBSCRIPTION_TYPES.MONTHLY) {
          return USER_ROLES.MONTHLY_FREE_TRIAL;
        }
        if (user.subscription.type === SUBSCRIPTION_TYPES.YEARLY) {
          return USER_ROLES.YEARLY_FREE_TRIAL;
        }
        if (user.subscription.type === SUBSCRIPTION_TYPES.QUARTERLY) {
          return USER_ROLES.QUARTERLY_FREE_TRIAL;
        }
      }
    }
  }
  if (hasFreeTrial(user.freeTrialExpiry, today)) {
    return USER_ROLES.FREE_TRIAL;
  }

  // Check if the user is a free host
  if (hasMinSessionsToBecomeFreeHost(fixedFormatSessionsHostedLast30Days)) {
    return USER_ROLES.FREE_HOST;
  }
  return USER_ROLES.FREE_TRIAL_EXPIRED;
};

export type UserRoleProps = {
  isAuthorizedRole: boolean;
  isExpiredRole: boolean;
  isYearlyRole: boolean;
  isQuarterlyRole: boolean;
  isMonthlyRole: boolean;
  isFreeTrialRole: boolean;
  isFreeRole: boolean;
  isFreeHostRole: boolean;
  isFreeCreditsRole: boolean;
  isFreeTrialExpiredRole: boolean;
  isRegisteredRole: boolean;
  isFlownRole: boolean;
  isCorporateAdminRole: boolean;
  isCorporateUserRole: boolean;
  isCorporateRole: boolean;
  isFacilitatorRole: boolean;
  isLifetimeRole: boolean;
  isPaidRole: boolean;
};

// External methods that determine what the user can do based on their role
export const getIsUserRoleProps = (userRole: UserRole): UserRoleProps => ({
  isExpiredRole: getIsExpiredRole(userRole),
  isAuthorizedRole: getIsAuthorizedRole(userRole),
  isYearlyRole: getIsYearlyRole(userRole),
  isQuarterlyRole: getIsQuarterlyRole(userRole),
  isMonthlyRole: getIsMonthlyRole(userRole),
  isFreeTrialRole: getIsFreeTrialRole(userRole),
  isFreeRole: getIsFreeRole(userRole),
  isFreeHostRole: getIsFreeHostRole(userRole),
  isFreeCreditsRole: getIsFreeCreditsRole(userRole),
  isRegisteredRole: getIsRegisteredRole(userRole),
  isFreeTrialExpiredRole: getIsFreeTrialExpiredRole(userRole),
  isFlownRole: getIsFlownRole(userRole),
  isCorporateAdminRole: getIsCorporateAdminRole(userRole),
  isCorporateUserRole: getIsCorporateUserRole(userRole),
  isCorporateRole: getIsCorporateRole(userRole),
  isFacilitatorRole: getIsFacilitatorRole(userRole),
  isLifetimeRole: getIsLifetimeRole(userRole),
  isPaidRole: getIsPaidRole(userRole),
});

export const getIsExpiredRole = (userRole: UserRole) =>
  userRole === USER_ROLES.EXPIRED;

export const getIsFreeRole = (userRole: UserRole) =>
  userRole === USER_ROLES.FREE;

export const getIsFreeOrFreeCreditsRole = (userRole: UserRole) =>
  getIsFreeRole(userRole) || getIsFreeCreditsRole(userRole);

export const getIsFreeHostRole = (userRole: UserRole) =>
  userRole === USER_ROLES.FREE_HOST;

export const getIsFreeCreditsRole = (userRole: UserRole) =>
  userRole === USER_ROLES.FREE_CREDITS;

export const getIsAuthorizedRole = (userRole: UserRole) =>
  AUTHORIZED_USER_ROLES.includes(userRole);

export const getIsFlownRole = (userRole: UserRole) =>
  userRole === USER_ROLES.FLOWN;

export const getIsFacilitatorRole = (userRole: UserRole) =>
  userRole === USER_ROLES.FACILITATOR;

const ADMIN_FACILITATORS = ["megan@howtowritesomething.com"];

export const getIsAdminFacilitator = (email: string) =>
  ADMIN_FACILITATORS.includes(email);

export const getIsInternalHost = (userRole: UserRole | undefined) => {
  if (userRole) {
    return getIsFlownRole(userRole) || getIsFacilitatorRole(userRole);
  }
  return false;
};

export const getIsUnsubscribedRole = (userRole: UserRole) =>
  UNSUBSCRIBED_USER_ROLES.includes(userRole);

export const getIsQuarterlyRole = (userRole: UserRole) =>
  userRole === USER_ROLES.QUARTERLY;

export const getIsYearlyRole = (userRole: UserRole) =>
  userRole === USER_ROLES.YEARLY;

export const getIsMonthlyRole = (userRole: UserRole) =>
  userRole === USER_ROLES.MONTHLY;

export const getIsLifetimeRole = (userRole: UserRole) =>
  userRole === USER_ROLES.LIFETIME;

export const getIsFreeTrialRole = (userRole: UserRole) =>
  userRole === USER_ROLES.FREE_TRIAL;

export const getIsPaidRole = (userRole: UserRole) =>
  PAID_USER_ROLES.includes(userRole);

export const getIsFreeTrialExpiredRole = (userRole: UserRole) =>
  userRole === USER_ROLES.FREE_TRIAL_EXPIRED;

/**
 * Users whose free trial has expired or that are considered to be
 * legacy by the system
 */
export const getIsRegisteredRole = (userRole: UserRole) =>
  getIsFreeTrialExpiredRole(userRole);

export const getIsCorporateAdminRole = (corporateRole: UserRole) =>
  corporateRole === USER_ROLES.CORPORATE_ADMIN;

export const getIsCorporateUserRole = (corporateRole: UserRole) =>
  corporateRole === USER_ROLES.CORPORATE_USER;

export const getIsCorporateRole = (corporateRole: UserRole) =>
  CORPORATE_ROLES.includes(corporateRole);

export const getIsFullMember = (userRole: UserRole) =>
  FLOWN_FULL_MEMBERS.includes(userRole);

export const MIN_SESSIONS_ATTENDED_NEWBIE = 5;
/**
 * A user that has attended less than 5 sessions is considered a newbie.
 * This is determined through the analyticsData property and lives separately
 * to the user roles in the platform. In theory, a monthly user could be a newbie
 * if they have attended less than 5 sessions.
 */
export const getIsNewbie = ({ analyticsData }: Pick<User, "analyticsData">) => {
  if (analyticsData?.sessionsAttended) {
    return analyticsData.sessionsAttended < MIN_SESSIONS_ATTENDED_NEWBIE;
  }
  // Assume a newbie has no analytics data
  return true;
};

/**
 * For FLOWN users that are also facilitators, we add a special tag to mark them as such
 */
export const getIsFlownFacilitator = ({ tags }: Pick<User, "tags">) =>
  tags?.some((tag) => tag.slug === FLOWN_FACILITATOR_TAG.slug);

const PREMIUM_ORGANISATION_SLUGS = ["kraken", "flown-org"];

// For organisations that have extra features such as slack integrations.  Short term solution
// we should probably add a bunch of flags on the organisation itself
export const getIsPremiumOrganisation = (
  organisation: CorporateData | UserOrganisationWithName | undefined
) => {
  return !!(
    organisation?.slug && PREMIUM_ORGANISATION_SLUGS.includes(organisation.slug)
  );
};
