import { Partnership } from "./partnership.types";
import { TagRecord } from "generated/graphql-typed";
import { Facilitator } from "./facilitator.types";
import { Category } from "./products.types";
import { User } from "database/collections/users";
import { P2PEvent, RoomProperties } from "shared/rooms.types";
import { Agenda } from "features/sessions/shared";
import { z } from "zod";
import { ResultOrError } from "./response.types";
import { UserRole } from "services/roles/user-roles";

export type EventTag = Pick<TagRecord, "displayName" | "slug" | "hideForUsers">;

export type EventFlags = {
  isUserRegistered?: boolean;
};

/**
 * This entity represents anything that may be considered an event. This can
 * be an event from the calendar, a drop-in or a peer-to-peer session.
 * You will find all the event properties we use here :)
 */
export type CoreEvent = {
  agenda?: Agenda;
  id: string;
  springBoardUrl: string;
  springBoardFullUrl: string;
  springBoardGenericFullUrl: string;
  startTime: string;
  endTime: string;
  displayName: string;
  shortDescription: string;
  longDescription?: string;
  icon: {
    url: string;
  };
  /**
   * The name that we match the event against in the calendar - this is how facilitators
   * know which sessions are which across different timezones. e.g TO, DD1, DD2, PH, etc
   * It corresponds to the event.summary field in the Google Calendar API
   * Used internally in Eagle
   */
  gcalSessionName?: string;
  sessionUrl: string;
  slug: string;
  category: {
    displayName: string;
    slug: Category;
  };
  tags?: EventTag[];
  startTimeParam: string;
  // TODO reafactor update this prop name to something link `dailyName`
  detailedName: string; // Used for analytics
  facilitator?: Facilitator | null;
  populatedHost?: PopulatedHost;
  populatedClaimedHost?: PopulatedHost;
  claimedHost?: string;
  host?: string;
  isP2P?: boolean;
  registeredUsersCount?: number;
  usersInSessionCount?: number;
  deleted?: boolean; // This is for p2p events and has much more complicated filtering on where it shows.
  isCancelled?: boolean; // is cancelled doesn't get filtered out and shows everywhere - should be for flocks
  createdAt?: string;
  previewImageUrl?: string;
  durationDescription?: string | null;
  isFreeSession: boolean;
  flags: EventFlags;
  partnership?: Partnership | null;
  organisation?: {
    id: string;
    name: string;
    slug: string;
    logoUrl?: string;
    themeColor?: string;
  };
  details?: string;
  richDescription?: {
    value: any;
  } | null;
  zoomLink?: string;
};

// User events will have this prefix on their id
export const USER_EVENT_PREFIX = "user-";
export const P2P_EVENT_PREFIX = "com-";
export const FOCUS_ROOM_PREFIX = "focus-";
export const P2P_EVENT_PREFIX_REGEX = new RegExp(/^com-/);

/**
 * This represents an event that is created by a user. Usually booking a session
 */
// TODO: extract the user type that is shared to a common place in another PR
export type PopulatedHost = Required<
  Pick<
    User,
    | "lastName"
    | "firstName"
    | "externalId"
    | "avatarUrl"
    | "headline"
    | "oneLiner"
    | "displayName"
  >
> & {
  email?: string;
  userRole?: UserRole;
  totalSessions?: number;
};

/**
 * This is a central entity in our application. It represents the events after they are fetched from the
 * Google Calendar API and merged with the metadata from DatoCMS.
 */
export type CalendarEvent = CoreEvent &
  Partial<RoomProperties> & {
    gcalId: string;
    zoomLink: string;
    isP2P: false;
    isFocusRoom: boolean;
  };

export type CalendarEvents = CalendarEvent[];

export type FlownEvent = CalendarEvent | P2PEvent;

export type FlownEvents = FlownEvent[];

export const findUncachedEventSchema = z.object({
  eventId: z.string(),
});

export type FindUncachedEventResponse = ResultOrError<{
  event: CalendarEvent;
}>;
