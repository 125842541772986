import dayjs from "dayjs";
import { SessionEventCommonProps } from "features/analytics";
import { FlownEvent, USER_EVENT_PREFIX } from "shared/event.types";
import { P2PEvent } from "shared/rooms.types";
import { pluralise } from "./string-utils";
import {
  FEATURED_TAG,
  FIXED_FORMAT_SESSION_TAG,
  FLOCK_TYPES,
  FLOWN_SESSION_TAG,
  NEXT_GEN_SESSION_TAG,
  POPULAR_NEWBIE_SESSION_TAG,
  POPULAR_WITH_ORG_TAG,
  SHOW_IN_ORG_CALENDAR_TAG,
  SessionSource,
} from "./constants";
import { Agenda, getAgendaText } from "features/sessions/shared";
import { getUserDisplayName } from "features/profile/shared";
import { User } from "database/collections/users";
import { SESSIONS_GO_BASE_URL, WAITING_ROOM_SUFFIX } from "./urls";
import { isProductionMode } from "./environment";
import { graphqlQuery } from "services/graphql";
import { EventsDocument } from "generated/graphql-typed";
import { EventSummaryFlownEvent } from "design-system/molecules/event-summary";
import { getIsInternalHost } from "services/roles/user-roles";

// TODO: refactor this file so that related methods live together
export const POPULAR_WITH_ORG_THRESHOLD = 2;

export const getDurationDisplay = (minutes: number) => {
  if (minutes < 60) {
    return `${minutes}min`;
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (remainingMinutes === 0) {
      return `${hours}h`;
    } else {
      return `${hours}h ${remainingMinutes}min`;
    }
  }
};

export const transformTimestampToDate12HourTime = (stringTimestamp: string) => {
  const timestamp = new Date(stringTimestamp);

  const time = dayjs(timestamp).format("DD, MMM, YYYY, h:mma");
  return time;
};

export const getSessionUrlWithSource = (
  sessionUrl: string,
  source: SessionSource
) => sessionUrl + "?source=" + source;

// Add this tag to the event to allow us to launch in zoom
// you need to update the session url to match the zoom url
// This is a temporary solution until we can use daily rooms for all sessions.
export const TAG_LAUNCH_IN_ZOOM = {
  slug: "launch-in-zoom",
  displayName: "Launch In Zoom",
  hideForUsers: true,
};
// This allows us to add a custom tag so that we use just the sessionURl and not have the waiting room
// This means we can create a private p2p session and allow us to use zoom
export const getP2PSessionUrl = (event: FlownEvent, source: SessionSource) =>
  event.tags?.map((tag) => tag.slug).includes(TAG_LAUNCH_IN_ZOOM.slug)
    ? `${SESSIONS_GO_BASE_URL}/${event.id}`
    : getSessionWaitingRoomUrlWithSource(event.sessionUrl, source);

export const getSessionWaitingRoomUrlWithSource = (
  sessionUrl: string,
  source: SessionSource
) => `${sessionUrl}${WAITING_ROOM_SUFFIX}` + "?source=" + source;

export const getIsEventXMinutesIn = (startTime: string, threshold: number) =>
  dayjs().diff(dayjs(startTime), "minutes", true) >= threshold;
/**
 * Amount of minutes after the event start where we allow the users to join
 * the event.
 * e.g. event starts at 10:00 and users will see join related copy up until
 * 10:10
 */
export const JOINING_WINDOW_MINS = 10;
export const HOST_JOINING_WINDOW_MINS = 15;

/**
 * Amount of minutes before the event start where we allow the users to join
 * the event.
 * e.g. event starts at 10:00 and users will see join related copy from
 * 9:55
 */
const PRE_JOINING_WINDOW_MINS = 10;

export const getIsDropInEvent = (event: Pick<FlownEvent, "slug">) =>
  event.slug === FLOCK_TYPES.DROP_IN;

/**
 * Tag that can be used as a feature flag when we want to specifically
 * turn on Daily Prebuilt or in-progress improvements for a specific session
 */
export const isNextGenSession = (event: Pick<FlownEvent, "tags">) =>
  event.tags?.some((tag) => tag.slug === NEXT_GEN_SESSION_TAG.slug);

const isFixedFormatSession = (event: Pick<FlownEvent, "tags">) =>
  event.tags?.some((tag) => tag.slug === FIXED_FORMAT_SESSION_TAG.slug);

export const getIsPublicFixedFormatSession = (
  event: Pick<FlownEvent, "tags" | "privacy">
) => event.privacy === "public" && isFixedFormatSession(event);

export const getIsCommunityEvent = (
  event: Pick<FlownEvent, "isP2P" | "tags">
) =>
  event.isP2P &&
  !event.tags?.some((tag) => tag.slug === FLOWN_SESSION_TAG.slug);

export const getIsFlownHostedEvent = (
  event: Pick<FlownEvent, "facilitator" | "populatedHost">
) => !!event.facilitator || getIsInternalHost(event.populatedHost?.userRole);

export const getIsFeaturedCommunityEvent = (
  event: Pick<FlownEvent, "isP2P" | "tags">
) =>
  getIsCommunityEvent(event) &&
  event.tags?.some((tag) => tag.slug === FEATURED_TAG.slug);

export const getShouldShowInOrgCalendar = (event: Pick<FlownEvent, "tags">) =>
  event.tags?.some((tag) => tag.slug === SHOW_IN_ORG_CALENDAR_TAG.slug);

export const isPowerHour = (event: Pick<FlownEvent, "slug">) =>
  event.slug === FLOCK_TYPES.POWER_HOUR;

export const isDeepDive = (event: Pick<FlownEvent, "slug">) =>
  event.slug === FLOCK_TYPES.DEEP_DIVE ||
  event.slug === FLOCK_TYPES.DEEP_DIVE_2 ||
  event.slug === FLOCK_TYPES.DEEP_DIVE_3 ||
  event.slug === FLOCK_TYPES.DEEP_DIVE_9AM;

export const isTakeOff = (event: Pick<FlownEvent, "slug">) =>
  event.slug === FLOCK_TYPES.TAKE_OFF;

export const getSessionDisplayName = (
  event: Pick<FlownEvent, "displayName" | "slug">
) => {
  const _isDeepDive = isDeepDive(event);
  const _isPowerHour = isPowerHour(event);
  return _isDeepDive
    ? "2 hour focus session"
    : _isPowerHour
    ? "1 hour focus session"
    : event.displayName;
};

export const getIsEventJoinable = (
  event: Pick<
    FlownEvent,
    "startTime" | "endTime" | "slug" | "deleted" | "isCancelled"
  >,
  onlyJoinableInInitialWindow = false,
  isHost = false
) => {
  if (event.deleted || event.isCancelled) {
    return false;
  }

  if (
    getIsDropInEvent(event) &&
    dayjs(event.endTime).add(15, "hours").isAfter(dayjs()) &&
    dayjs(event.startTime).subtract(12, "hours").isBefore(dayjs())
  ) {
    // Today's drop-in events are joinable
    return true;
  }

  const canJoinUpUntil = dayjs(event.startTime)
    .add(isHost ? HOST_JOINING_WINDOW_MINS : JOINING_WINDOW_MINS, "minute")
    .isAfter(dayjs());

  // This sets if we can join only in the initial window (not through the whole event)
  if (onlyJoinableInInitialWindow) {
    return canJoinUpUntil && getEventInProgress(event.startTime, event.endTime);
  }

  return getEventInProgress(event.startTime, event.endTime);
};
export const getEventHasStarted = (eventStartTime: string) =>
  dayjs().diff(dayjs(eventStartTime), "seconds", true) > 0;

export const getEventHasEnded = (eventEndTime: string) =>
  dayjs().isAfter(dayjs(eventEndTime));

// You can join X mins before but not 5 mins from the end - this is to prevent
// people joining the wrong session when two back to back;
export const getEventInProgress = (
  eventStartTime: string,
  eventEndTime: string
) =>
  eventStartTime <=
    dayjs().add(PRE_JOINING_WINDOW_MINS, "minutes").toISOString() &&
  eventEndTime > dayjs().add(5, "minutes").toISOString();

/**
 * This varibable represents the threshold up until
 * which we consider an event to have just started so that
 * we can show this in the card.
 * It can also be used to show that an event is about to start
 */
const EVENT_JUST_STARTED_THRESHOLD_MIN = 5;

/**
 *
 * @param event
 * @returns whether the event is between the start time and EVENT_JUST_STARTED_THRESHOLD_MIN
 * mins after the startTime
 */
export const getHasEventJustStarted = (event: Pick<FlownEvent, "startTime">) =>
  dayjs().isAfter(dayjs(event.startTime)) &&
  dayjs().isBefore(
    dayjs(event.startTime).add(EVENT_JUST_STARTED_THRESHOLD_MIN, "minutes")
  );

/**
 *
 * @param event
 * @returns whether the event is between the start time and EVENT_JUST_STARTED_THRESHOLD_MIN
 * mins before the startTime
 */
export const getIsEventAboutToStart = (event: Pick<FlownEvent, "startTime">) =>
  dayjs().isBefore(dayjs(event.startTime)) &&
  dayjs().isAfter(
    dayjs(event.startTime).subtract(EVENT_JUST_STARTED_THRESHOLD_MIN, "minutes")
  );

export const getIsEmptyConfig = (config: any) =>
  !Object.keys(config).some((configKey) => config[configKey].length > 0);

export const isEventRegistered = (
  eventsRegisteredIds: string[],
  event: Pick<FlownEvent, "slug" | "id" | "startTime">
) => {
  if (getIsDropInEvent(event)) {
    const eventStartTime = dayjs(event.startTime).format("YYYY-MM-DD");
    return !!eventsRegisteredIds.find(
      (bookedEvent) =>
        bookedEvent.includes(event.slug) && bookedEvent.includes(eventStartTime)
    );
  }

  return eventsRegisteredIds.includes(event.id);
};

export const getIsStartTimeInPast = (date: string) => {
  const now = dayjs().subtract(JOINING_WINDOW_MINS, "minutes");
  return dayjs(date).isBefore(now);
};

const buttonPrimary = (text: string, url: string) =>
  `<a href="${url}" target="_blank" style="padding: 8px 12px; border: 1px solid #18181D;background:#18181D;border-radius: 9999px;font-family: Helvetica, Arial, sans-serif;font-size: 14px; color: #ffffff;text-decoration: none;font-weight:bold;display: inline-block; margin-right:8px">${text}</a>`;

const buttonSecondary = (text: string, url: string) =>
  `<a href="${url}" target="_blank" style="padding: 8px 12px; border: 1px solid #F8F8F9;background:#F8F8F9;border-radius: 9999px;font-family: Helvetica, Arial, sans-serif;font-size: 14px; color: #18181D;text-decoration: none;font-weight:bold;display: inline-block; ">${text}</a>`;

export const createInvite = async (event: FlownEvent) => {
  if (event.isP2P) {
    return createInviteText(
      `<h1>${event.displayName}</h1>\n\n` +
        `<b>Hosted By: ${getUserDisplayName(event.populatedHost)}</b>\n\n` +
        `<b>Duration: ${event.durationDescription}</b>\n\n` +
        `<h2>How it works:</h2>\n\n` +
        event.shortDescription +
        "\n\n" +
        (event.agenda && getAgendaText(event.agenda)),

      event.springBoardFullUrl
    );
  } else {
    const { allScheduledSessions } = await graphqlQuery(EventsDocument);
    const sessionData = allScheduledSessions.find(
      (session) => session.slug === event.slug
    );

    if (sessionData && sessionData.emailDescription) {
      return createInviteText(
        sessionData.emailDescription,
        event.springBoardFullUrl
      );
    }
  }

  return "You have been invited to a FLOWN session";
};

export const createInviteText = (
  emailDescription: string,
  sessionUrl: string
) =>
  `${buttonPrimary("Join", sessionUrl)} ${buttonSecondary(
    "Manage or cancel your booking",
    sessionUrl
  )}
    \n  \n ${emailDescription} \n
    ${buttonPrimary("Join", sessionUrl)} ${buttonSecondary(
    "Manage or cancel your booking",
    sessionUrl
  )} \n`
    .trim()
    .split(/\s*\n\s*/)
    .join("\n\n");

export const createIcalDescription = ({
  agenda,
  sessionUrl,
  shortDescription,
}: {
  agenda: Agenda;
  shortDescription: string;
  sessionUrl: string;
}) =>
  `${buttonPrimary("Join", sessionUrl)}\n${buttonSecondary(
    "Manage or cancel your booking",
    sessionUrl
  )} \n ${shortDescription} \n ${getIcalAgendaText(agenda)}`
    .trim()
    .split(/\s*\n\s*/)
    .join("\n\n");

const getIcalAgendaText = (agenda: Agenda) => {
  return agenda
    .map(
      (item) =>
        `&bull; ${getDurationDisplay(item.duration)}: ${item.displayName}`
    )
    .join("\n");
};

export const convertRoomToSessionEvent = (
  room: P2PEvent,
  source = "P2P",
  recurId = ""
): SessionEventCommonProps => {
  return {
    eventId: room.id,
    type: source === "P2P" ? "P2P" : "Focus Room",
    detailedName: room.detailedName,
    event_date: room.startTime,
    displayName: room.displayName.replace(/"/g, `'`),
    durationDescription: room.durationDescription || "",
    shortDescription: room.shortDescription,
    privacy: room.privacy,
    category: room.category.displayName, // We cast here as dynamic
    tags: room?.tags?.map((tag) => tag.displayName || "") || [],
    source,
    startTime: room.startTime,
    event_end: room.endTime,
    facilitatorName: getUserDisplayName(room.populatedHost),
    facilitatorId: room.host,
    facilitatorPhoto: room.populatedHost?.avatarUrl || "",
    recurId,
    claimHost: room.claimedHost || "",
    populatedClaimedHost: room.populatedClaimedHost || "",
    populatedHost: room.populatedHost || "",
    isFreeSession: room.isFreeSession,
  };
};

// This is a stripped down event for analytics which
// can't deal with large nested payloads
export const convertFlownEventToSessionEvent = (
  flownEvent: FlownEvent,
  source = "none"
): SessionEventCommonProps => {
  if (flownEvent.isP2P) {
    return convertRoomToSessionEvent(flownEvent, source);
  } else {
    return {
      eventId: flownEvent.id,
      type: flownEvent.slug,
      detailedName: flownEvent.detailedName,
      category: flownEvent.category.slug,
      tags: flownEvent.tags?.map((tag) => tag.displayName || "") || [],
      source,
      startTime: flownEvent.startTime,
      event_date: flownEvent.startTime,
      facilitatorName: flownEvent.facilitator?.name || "",
      facilitatorPhoto: flownEvent.facilitator?.photoUrl || "",
      facilitatorId: "",
      displayName: flownEvent.displayName,
      event_end: flownEvent.endTime,
      shortDescription: flownEvent.shortDescription,
      durationDescription: flownEvent.durationDescription || "",
      populatedClaimedHost: "",
      populatedHost: "",
      privacy: "public",
      recurId: "",
      claimHost: "",
      isFreeSession: flownEvent.isFreeSession,
    };
  }
};

export const getIsEventFullyBooked = (
  event: Pick<FlownEvent, "config" | "registeredUsersCount">
) => {
  if (event.config?.max_participants && event.registeredUsersCount) {
    return event.registeredUsersCount >= event.config.max_participants;
  }
  return false;
};

export const getIsWaitlist = (
  event: Pick<
    FlownEvent,
    "forceWaitingRoom" | "isFocusRoom" | "config" | "registeredUsersCount"
  >
) => {
  return (
    !event.forceWaitingRoom &&
    !event.isFocusRoom &&
    getIsEventFullyBooked(event)
  );
};

const SHOW_SPOTS_LEFT_WHEN_LESS_THAN = 3;
export const SESSION_FULL_COPY = "🙅 Fully booked";

export const getEventCapacityStatus = (
  event: Pick<FlownEvent, "config" | "registeredUsersCount">,
  isHost = false
) => {
  if (event.config?.max_participants && event.registeredUsersCount) {
    const spotsLeft =
      event.config.max_participants - event.registeredUsersCount;

    if (spotsLeft <= 0) {
      return SESSION_FULL_COPY;
    }

    if (spotsLeft < SHOW_SPOTS_LEFT_WHEN_LESS_THAN) {
      return `🔥 ${spotsLeft} ${pluralise(spotsLeft, "spot")} left`;
    } else if (isHost) {
      return `${spotsLeft}/${event.config.max_participants} ${pluralise(
        spotsLeft,
        "spot"
      )} left`;
    }
  }
};
export const HOSTLESS_EXTERNAL_ID = isProductionMode()
  ? "65df4200ed76f90af6f5d053" // dev+hostless@flown.com
  : "61c83769fc34963195b1ad98"; // qa+hostless@flown.com

export const checkIsHostless = (
  event: Pick<P2PEvent, "host" | "claimedHost">
) => event?.host === HOSTLESS_EXTERNAL_ID && !event?.claimedHost;

export const getIsCohost = (event: Pick<P2PEvent, "cohosts">, userId: string) =>
  event?.cohosts.includes(userId);

export const getHasHostPrivileges = (
  event: Pick<P2PEvent, "host" | "cohosts" | "claimedHost">,
  userId: string
) =>
  event?.host === userId ||
  event?.cohosts.includes(userId) ||
  event?.claimedHost === userId;

export const getIsHostInSession = (
  event: Pick<P2PEvent, "host" | "cohosts" | "claimedHost">,
  usersInSession: Pick<User, "externalId">[]
) =>
  usersInSession.some(
    (user) =>
      user.externalId === event?.host ||
      event?.cohosts.includes(user.externalId) ||
      event?.claimedHost === user.externalId
  );

export const getDateFromEventId = (eventId: string) => {
  const startDateRegex = /^(\d{4}-\d{2}-\d{2})/;
  const startDate = eventId.match(startDateRegex)?.[1];
  return startDate;
};

export const isPopularWithNewbies = (event: Pick<FlownEvent, "tags">) =>
  event.tags?.some((tag) => tag.slug === POPULAR_NEWBIE_SESSION_TAG.slug);

export const isPopularWithOrganisation = (event: Pick<FlownEvent, "tags">) =>
  event.tags?.some((tag) => tag.slug === POPULAR_WITH_ORG_TAG.slug);

export const sortByMostRecent = (
  a: Pick<FlownEvent, "startTime">,
  b: Pick<FlownEvent, "startTime">
) => (a.startTime > b.startTime ? 1 : -1);

/**
 *
 * 04/02/2025 - By default, P2P sessions are free for members. Sessions can be featured, which is the case for:
 * - Fixed format sessions
 * - Sessions featured by the team
 *
 * If a session is featured it is considered to be part of the premium calendar and therefore
 * not accessible to Free users
 */
export const getIsFreeSessionForCommunityEvent = ({
  startTime,
  tags,
}: Pick<FlownEvent, "startTime" | "tags">) => {
  // if it's a featured room and it's not a friday we make the session not free
  if (tags && tags.some((tag) => tag.slug === FEATURED_TAG.slug)) {
    // We do a bit of a window here to account for different timezones
    const isFriday =
      dayjs(startTime).add(4, "hours").day() === 5 ||
      dayjs(startTime).subtract(4, "hours").day() === 5;
    if (!isFriday) {
      return false;
    }
  }
  return true;
};

export const getSessionHost = (event: EventSummaryFlownEvent | FlownEvent) =>
  event.isP2P && Object.keys(event.populatedClaimedHost || {}).length > 0
    ? event.populatedClaimedHost
    : event.populatedHost;

export const getIsUserEvent = (eventId: string) => {
  return eventId.startsWith(USER_EVENT_PREFIX);
};

export const sortEventsByPriority = (a: FlownEvent, b: FlownEvent) => {
  // If both events have the same start time or have both started:
  if (
    a.startTime === b.startTime ||
    (getEventHasStarted(a.startTime) && getEventHasStarted(b.startTime))
  ) {
    // Prioritize drop-in events
    if (getIsDropInEvent(a) && !getIsDropInEvent(b)) return -1;
    if (getIsDropInEvent(b) && !getIsDropInEvent(a)) return 1;
    // If one is Flown hosted, prioritize that one
    if (getIsFlownHostedEvent(a) && !getIsFlownHostedEvent(b)) return -1;
    if (getIsFlownHostedEvent(b) && !getIsFlownHostedEvent(a)) return 1;
    // If one is fully booked, prioritize the non-fully booked one
    if (getIsEventFullyBooked(a) && !getIsEventFullyBooked(b)) return -1;
    if (getIsEventFullyBooked(b) && !getIsEventFullyBooked(a)) return 1;
    // Sort by host attended sessions
    const aTotalSessions = a.populatedHost?.totalSessions || 0;
    const bTotalSessions = b.populatedHost?.totalSessions || 0;
    if (aTotalSessions > bTotalSessions) return -1;
    if (aTotalSessions < bTotalSessions) return 1;
  }

  // Otherwise sort by start time
  return a.startTime > b.startTime ? 1 : -1;
};
